import classnames from 'classnames';
import { forwardRef, type CSSProperties } from 'react';

import type {
  ColorToken,
  TamagoshiComponent,
} from '@/core/tamagoshiv2/interfaces';

import styles from './Loader.module.scss';

/** Info needed to manage progress attributes while loading something. */
export interface ProgressInfo {
  /** Maximum value used for ARIA representation */
  valueMax?: number;
  /** Minimum value used for ARIA representation */
  valueMin?: number;
  /** Current value used for ARIA representation */
  valueNow?: number;
}

export interface LoaderProps extends TamagoshiComponent, ProgressInfo {
  /** The color of the spinner */
  color?: ColorToken;
  /** The accessible label for the spinner */
  label?: string;
}

/**
 * A loader to make people wait while you load or do something.
 * @deprecated Use `core/tamagoshiTailwind/components/Loader` instead.
 */
export const Loader = forwardRef<HTMLDivElement, LoaderProps>(
  (
    { className, color, label, style, valueMax, valueMin, valueNow, ...props },
    ref,
  ) => (
    <div
      aria-valuemin={valueMin}
      aria-valuemax={valueMax}
      aria-valuenow={valueNow}
      aria-valuetext={label}
      className={classnames(styles.loader, className)}
      ref={ref}
      role="progressbar"
      style={{ ...style, '--dot-color': color } as CSSProperties}
      {...props}
    >
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
    </div>
  ),
);

Loader.displayName = 'Loader';
