import type { SvgProps } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { CSSProperties } from 'react';

export interface TamagoshiComponent {
  /** A className passed from the parent for sizes, margins and position */
  className?: string;
  /** Data test id used for unit tests when needed */
  'data-testid'?: string;
  /** The id of the component */
  id?: string;
  /** A style passed from the parent for sizes, margins and position */
  style?: CSSProperties;
}

export type TamagoshiIcon = TamagoshiComponent &
  Omit<SvgProps, 'children' | 'height' | 'style' | 'viewBox' | 'width'>;

// TODO We might want to change this enum to a simpler union so that consumers
//      can safely use strings directly without having to import the enum.
export enum SIZE {
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
}

// NOTE Make unique types that can be cast to strings automatically so that the
//      typing system can recognize forbidden types in component properties.
//      The `unique symbol` hack is there to make sure the generated types are
//      unique, kind of like enum but castable to strings.
export type ColorToken = string & { readonly '': unique symbol };
export type RadiusToken = string & { readonly '': unique symbol };
export type SpacingToken = string & { readonly '': unique symbol };
export type ShadowToken = string & { readonly '': unique symbol };
