import classnames from 'classnames';
import {
  createElement,
  forwardRef,
  type ComponentClass,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type FunctionComponent,
} from 'react';

import {
  Loader,
  type ProgressInfo,
} from '@/core/tamagoshiv2/components/Loader/Loader';
import { Span } from '@/core/tamagoshiv2/components/Typography/Typography';
import { SIZE, type TamagoshiComponent } from '@/core/tamagoshiv2/interfaces';

import styles from './GenericButton.module.scss';

type CleanedButtonProps<T> = Omit<
  T,
  'color' | 'size' | 'content' | 'children' | 'type'
>;

/** If loading you should pass a label and add progress info if you have it. */
interface LoadingInfo extends ProgressInfo {
  // TODO We probably want to allow undefined labels as they don't make sense in
  //      the case of indeterminate progress values.
  label: string;
}

/** All button types that can be disabled. */
export const DisableableTypes = {
  none: 'none',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
} as const;

/** All button types that can never be disabled. */
export const NotDisableableTypes = {
  // NOTE There used to be more types you were not allowed to disable so a
  //      separate specification made sense at the time. We can probably remove
  //      it now.
  link: 'link',
} as const;

// NOTE The split is useful in order to prevent disabling warning buttons.
type ButtonWithVariants =
  | {
      /** Disable the button. Some variants of buttons cannot be disabled */
      disabled?: never;
      /** The variant. Use `none` to create new variants specific to a domain */
      type?: keyof typeof NotDisableableTypes;
    }
  | {
      disabled?: boolean;
      type?: keyof typeof DisableableTypes;
    };

interface LabelAndIcon {
  label: string;
  icon: FunctionComponent | ComponentClass;
}

export type GenericButtonProps<T extends ElementType> = CleanedButtonProps<
  ComponentPropsWithoutRef<T>
> &
  ButtonWithVariants &
  TamagoshiComponent & {
    /**
     * The content of the button, can be a label or a label and icon.
     * If using an icon-only button, the label is mandatory for accessbility
     * purposes.
     */
    content: string | LabelAndIcon;
    /** Hide the label of the button. This is useful for icon-only buttons */
    hideLabel?: boolean;
    /** The HTML type of the button */
    htmlType?: 'button' | 'submit' | 'reset';
    /** Position of the icon relative to the text if there is one */
    iconPosition?: 'left' | 'right';
    /** Loading data. If provided, a spinner is used intead of the content */
    loading?: LoadingInfo | false;
    /** The size of the button */
    size?: `${SIZE.LARGE}` | `${SIZE.MEDIUM}`;
    /**
     * Customize the button width.
     * - `fillContainer` makes the button take the available parent width.
     * - `hugContent` makes the button take as little as needed.
     * - `hugContentCentered` is the same but centers it within the parent.
     */
    width?: 'fillContainer' | 'hugContent' | 'hugContentCentered';
  };

type Props<T extends ElementType> = GenericButtonProps<T> & {
  asComponent: T;
};

/**
 * A generic button used to implement other buttons from the Design System.
 * @deprecated Use `Button`, `CommandButton` or `NavigationButton` instead.
 */
export const GenericButton = forwardRef(
  <Tag extends ElementType>(
    {
      asComponent,
      className,
      content,
      disabled = false,
      hideLabel = false,
      htmlType = 'button',
      iconPosition = 'left',
      loading,
      size = SIZE.MEDIUM,
      type = 'primary',
      width = 'hugContent',
      ...props
    }: Props<Tag>,
    ref: ForwardedRef<ElementRef<Tag>>,
  ) => {
    let label;
    let Icon;
    if ((content as LabelAndIcon).label) {
      ({ label, icon: Icon } = content as LabelAndIcon);
    } else {
      label = content as string;
    }
    return createElement(
      asComponent,
      {
        ...props,
        ref,
        type: htmlType,
        disabled: disabled || !!loading,
        className: classnames(
          styles.button,
          styles[`size${size}`],
          styles[type],
          styles[width],
          { [styles.loading]: !!loading },
          { [styles.disabled]: disabled || !!loading },
          className,
        ),
        'aria-live': 'polite',
        'aria-disabled': disabled || !!loading,
        'aria-label': label,
      },
      <>
        {Icon && iconPosition === 'left' && <Icon />}
        {label && !hideLabel && (
          <Span
            className={styles.label}
            type="semiBold"
            variant={({ L: 'body1', M: 'body2' } as const)[size]}
          >
            {label}
          </Span>
        )}
        {Icon && iconPosition === 'right' && <Icon />}
        {!!loading && (
          <Loader data-testid="loader" className={styles.loader} {...loading} />
        )}
      </>,
    );
  },
);

GenericButton.displayName = 'GenericButton';
